import {
    Autocomplete,
    CardMedia,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import MainContainer from '../../layout/MainContainer';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    useLazyGetBotNFTQuery,
    useLazyGetCategoryQuery,
    useLazyGetSubCategoryByIdQuery,
    useLazyRejectNFTQuery,
    useAcceptNFTMutation,
} from '../../services/main';
import Loader from '../../constants/Loader';
import { useEffect, useState } from 'react';
import {
    STORAGE_KEYS,
    errorToast,
    getFromStorage,
    successToast,
} from '../../helpers';
import { Category, CommonBody } from '../../types/General';
import { useAppDispatch } from '../../hooks/store';
import { setCredentials } from '../../reducers/authSlice';
import useAuth from '../../hooks/useAuth';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isNumber, isString } from '../../utils/validation';
import { generateEncryptedKeyBody } from '../../utils/crypto';

function UpdateChatBotNFT() {
    const user = useAuth();
    const [getNFTData, setNFTData] = useState<any>({});
    const [categories, setCategories] = useState<Category[]>([]);
    const [subcategory, setSubCategory] = useState<Category[]>([]);
    const [selectedCate, setSelectedCate] = useState('');
    const [selectedsubCate, setSelectedSubCate] = useState('');
    const [error, setError] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [getBotNFTData, { isLoading }] = useLazyGetBotNFTQuery();
    const [rejectNFTData] = useLazyRejectNFTQuery();
    const [getCategory] = useLazyGetCategoryQuery();
    const [getSubcategory] = useLazyGetSubCategoryByIdQuery();
    const [acceptNFTCall, { isLoading: isAcceptNFTLoading }] =
        useAcceptNFTMutation();
    const dispatch = useAppDispatch();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            brandName: getNFTData?.brandName,
            description: getNFTData?.description,
            carbonCredit: '',
            weight: getNFTData?.weight,
            quantity: getNFTData?.quantity,
            I2EPoints: '',
            //getNFTData?.assetCate
        },
        validationSchema: Yup.object({
            brandName: Yup.string()
                .required('This field is required')
                .min(2, 'Minimum 2 characters are required')
                .max(80, 'Maximum 80 character are allowed'),
            carbonCredit: Yup.string().required('This field is required'),
            I2EPoints: Yup.string().required('This field is required'),
            description: Yup.string()
                .min(2, 'Minimum 2 characters are required')
                .max(3000, 'Maximum 3000 character are allowed'),
        }),
        onSubmit: async () => {
            console.log('here.......');
            formik.setSubmitting(true);

            if (!selectedCate || !selectedsubCate) {
                console.log('here i am');
                return;
            }

            let body = {
                photo_url: getNFTData?.photo_url,
                description: formik.values.description,
                name: getNFTData?.name,
                brandName: formik.values.brandName,
                basePrice: Number(formik.values.I2EPoints),
                categoryId: selectedCate,
                subCategoryId: selectedsubCate,
                status: 3,
                carbonCredit: Number(formik.values.carbonCredit),
                weight: Number(formik.values.weight),
                quantity: Number(formik.values.quantity),
                channelName: getNFTData?.social_media_type,
                collectionPoint: getNFTData?.collection_point_name,
                isApproved: true,
                nftType: 1,
                passiveNftType: 1,
                socialMediaType: getNFTData?.social_media_type,
                socialMediaId: getNFTData?.social_media_id,
                assetId: getNFTData?.id,
            };
            try {
                let encryptedBody = generateEncryptedKeyBody(
                    body
                ) as CommonBody;

                const response = await acceptNFTCall({
                    body: encryptedBody,
                    id: location.pathname.split('/').pop() as string,
                }).unwrap();
                if (response?.statusCode === 200) {
                    successToast('NFT Added Successfully');
                    navigate('/manage-chat-bot-nft');
                } else {
                    errorToast(response?.message || '');
                }
            } catch (error: any) {
                errorToast(error?.data?.message || '');
            }
        },
    });

    const rejectNFT = async (
        id: string,
        socialMediaId: string,
        socialMediaType: number,
        assetId: string
    ) => {
        try {
            const response = await rejectNFTData({
                id,
                socialMediaId,
                socialMediaType,
                assetId,
            }).unwrap();
            if (response.statusCode === 200) {
                navigate('/manage-chat-bot-nft');
            }
        } catch (error: any) {
            errorToast(error.data.message);
        }
    };

    const handleChangeCategorySelect = (
        event: React.ChangeEvent<object>,
        value: Category | null
    ) => {
        if (value !== null) {
            setSelectedCate(value?._id);
            setSelectedSubCate('');
            setSubCategory([]);
        } else {
            setSelectedCate(''); // Handle the case where no option is selected
        }
    };

    const handleChangeSubCategorySelect = (
        event: React.ChangeEvent<object>,
        value: Category | null
    ) => {
        if (value !== null) {
            setSelectedSubCate(value?._id);
        } else {
            setSelectedSubCate('');
        }
    };

    const getAllCategory = async () => {
        const token = getFromStorage(STORAGE_KEYS.token);
        dispatch(
            setCredentials({
                user: user,
                token: token,
            })
        );
        try {
            const res = await getCategory({
                size: 12,
                page: 1,
                query: '',
            }).unwrap();
            if (res?.statusCode === 200) {
                setCategories(res?.data?.category || []);
                setSelectedCate(res?.data?.category?.[0]?._id);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getAllSubCategory = async (id: string) => {
        if (id) {
            try {
                const res = await getSubcategory({
                    id,
                    size: 12,
                    page: 1,
                    query: '',
                }).unwrap();

                if (res?.statusCode === 200) {
                    setSubCategory(res?.data?.category || []);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getBotNFT = async (id: string) => {
        try {
            const response = await getBotNFTData({ id }).unwrap();
            if (response?.statusCode === 200) {
                console.log(response?.data);
                setNFTData(response?.data);
                // formik.setFieldValue('photo_url', response?.data?.photo_url);
                // formik.setFieldValue('name', response?.data?.name);
                // formik.setFieldValue('socialMediaId', response?.data?.user?.username);
                // formik.setFieldValue('collectionPoint', response?.data?.collection_point_name);
                // formik.setFieldValue('socialMediaType', response?.data?.social_media_type)
            }
        } catch (error: any) {
            errorToast(error?.data?.message || '');
        }
    };

    useEffect(() => {
        getBotNFT(location.pathname.split('/').pop() as string);
    }, []);

    useEffect(() => {
        getAllCategory();
    }, []);

    useEffect(() => {
        if (selectedCate) {
            getAllSubCategory(selectedCate);
        }
    }, [selectedCate, selectedCate]);

    return (
        <MainContainer>
            <Loader isLoad={isLoading} />
            <div className="coupon coupon_dtl_page">
                <div className="sec_head">
                    <h2>Update NFT</h2>
                    <div className="btn_group">
                        <div
                            className="btn btn_sm btn_primary"
                            onClick={() => navigate('/manage-chat-bot-nft')}
                        >
                            Back
                        </div>
                    </div>
                </div>
                <div className="form_group gap_m">
                    <div className="form_control form_control_bg  w_50">
                        <label>NFT Image</label>
                        <div className="upload_image  full_img">
                            <div className="upload_image_holder">
                                <figure>
                                    <CardMedia
                                        component="img"
                                        image={
                                            getNFTData?.photo_url ??
                                            '/images/placeholder_gallery.jpeg'
                                        }
                                        alt="photo"
                                    />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="coupon_frm_wrp">
                        <div className="form_group gap_m">
                            <div className="form_control form_control_bg w_50">
                                <label>Name</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Name"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="name"
                                    id="name"
                                    value={getNFTData?.name}
                                    disabled={!!getNFTData?.name}
                                />
                            </div>
                            <div className="form_control form_control_bg w_50">
                                <label>Brand Name</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Brand Name"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="brandName"
                                    id="brandName"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.'
                                        ) {
                                        } else {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.brandName}
                                />
                            </div>
                            <div className="form_control form_control_bg w_50">
                                <label>Channel Name</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Channel Name"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="channelName"
                                    id="channelName"
                                    value={
                                        getNFTData.social_media_type == 2
                                            ? 'Telegram'
                                            : getNFTData?.social_media_type == 1
                                                ? 'Facebook'
                                                : getNFTData?.social_media_type == 3
                                                    ? 'Twitter'
                                                    : getNFTData?.social_media_type == 4
                                                        ? 'LinkedIn'
                                                        : getNFTData?.social_media_type == 5
                                                            ? 'WhatsApp'
                                                            : 'Whatsapp'
                                    }
                                    disabled={!!getNFTData?.social_media_type}
                                />
                            </div>
                            <div className="form_control form_control_bg w_50">
                                <label>Assign To </label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Enter Social Media Id"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="socialMediaId"
                                    id="socialMediaId"
                                    value={getNFTData?.social_media_id}
                                    disabled={!!getNFTData?.social_media_id}
                                    helperText={
                                        getNFTData.userId
                                            ? null
                                            : 'User not avaliable with this social media id'
                                    }
                                />
                            </div>
                            <div className="form_control form_control_bg w_50">
                                <label>Carbon Credits</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Carbon Credits"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="carbonCredit"
                                    id="carbonCredit"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.'
                                        ) {
                                        } else if (isNumber(val.target.value)) {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.carbonCredit}
                                    helperText={
                                        formik.touched.carbonCredit &&
                                        formik.errors.carbonCredit
                                    }
                                />
                            </div>
                            <div className="form_control form_control_bg w_50">
                                <label>I2E Points</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="I2E Points"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="I2EPoints"
                                    id="I2EPoints"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.'
                                        ) {
                                        } else if (isNumber(val.target.value)) {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.I2EPoints}
                                    helperText={
                                        formik.touched.I2EPoints &&
                                        formik.errors.I2EPoints
                                    }
                                />
                            </div>
                            <div className="form_control form_control_bg w_50">
                                <label>Asset Weight (Grams)</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Carbon Credits"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="weight"
                                    id="weight"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.'
                                        ) {
                                        } else {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.weight}
                                />
                            </div>
                            <div className="form_control form_control_bg w_50">
                                <label>Number Of Items</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Number of Items"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="quantity"
                                    id="quantity"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.'
                                        ) {
                                        } else {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.quantity}
                                />
                            </div>
                            <div
                                style={{ display: 'none' }}
                                className="form_control form_control_bg w_50"
                            >
                                <label>Select Category</label>
                                <Autocomplete
                                    value={
                                        categories.find(
                                            (category) =>
                                                category._id === selectedCate
                                        ) || null
                                    }
                                    disabled={true}
                                    className="prfl_autocmplt"
                                    disablePortal
                                    id="combo-box-demo"
                                    onChange={handleChangeCategorySelect}
                                    options={[]}
                                    getOptionLabel={(option) => option?.name}
                                    sx={{ width: 'auto' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Categories"
                                        />
                                    )}
                                />
                            </div>
                            <div className="form_control form_control_bg w_50">
                                <label>Select Category</label>
                                <Autocomplete
                                    className="prfl_autocmplt"
                                    disablePortal
                                    id="combo-box-demo"
                                    onChange={handleChangeSubCategorySelect}
                                    options={
                                        subcategory?.length ? subcategory : []
                                    }
                                    getOptionLabel={(option) => option?.name}
                                    sx={{ width: 'auto' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Categories"
                                        />
                                    )}
                                />
                                {!selectedsubCate && error ? (
                                    <h6 className="err_msg">
                                        This field is required
                                    </h6>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="form_control form_control_bg w_50">
                                <label>Collection Center</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Collection Center"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="collectionPoint"
                                    id="collectionPoint"
                                    value={getNFTData?.collection_point_name}
                                    disabled={
                                        !!getNFTData?.collection_point_name
                                    }

                                />
                            </div>


                            <div
                                style={{ display: 'none' }}
                                className="form_control form_control_bg w_50"
                            >
                                <label>Status </label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Status"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="status"
                                    id="status"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.' ||
                                            val.target.value?.includes('@')
                                        ) {
                                        } else {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                        getNFTData?.status ===
                                            'pending_confirmation'
                                            ? 'pending'
                                            : getNFTData?.status
                                    }
                                    disabled={!!getNFTData?.status}
                                />
                            </div>
                            <div
                                className="form_control form_control_bg w_50"
                            >
                                <label>Asset type </label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Status"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="assetType"
                                    id="assetType"
                                    value={getNFTData?.isElectronic ? 'ELECTRONIC' : 'OTHER'}
                                />
                            </div>

                            <div className="form_control form_control_bg w_100">
                                <label>Short Note</label>
                                <TextField
                                    multiline
                                    maxRows={4}
                                    minRows={4}
                                    hiddenLabel
                                    placeholder="Enter Short Note"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="description"
                                    id="description"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.'
                                        ) {
                                        } else {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.description}
                                />
                            </div>
                            <div className="form_control form_control_bg w_100">
                                <label>Metadata Error</label>
                                <TextField
                                    multiline
                                    maxRows={4}
                                    minRows={4}
                                    hiddenLabel
                                    placeholder="Enter Short Note"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="errorDescription"
                                    id="errorDescription"
                                    value={getNFTData?.error}
                                />
                            </div>
                        </div>
                    </div>

                    {getNFTData?.status === 'pending_confirmation' &&
                        getNFTData.userId && (
                            <div className="submit_btn">
                                <button
                                    type="submit"
                                    className="btn btn_primary"
                                    style={{ marginRight: '2rem' }}
                                    onClick={() => setError(true)}
                                >
                                    Accept
                                </button>
                                <button
                                    type="button"
                                    className="btn btn_danger"
                                    onClick={() =>
                                        rejectNFT(
                                            location.pathname
                                                .split('/')
                                                .pop() as string,
                                            getNFTData?.social_media_id,
                                            getNFTData?.social_media_type,
                                            getNFTData?.id
                                        )
                                    }
                                >
                                    Reject
                                </button>
                            </div>
                        )}
                </form>
            </div>
        </MainContainer>
    );
}

export default UpdateChatBotNFT;
